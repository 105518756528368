var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "applicationContent" },
    [
      _c("div", { staticClass: "applicationHead" }, [_vm._v("更多功能")]),
      _c("div", [
        _vm.application.searchList.length
          ? _c("div", [
              _c("div", { staticClass: "applicationTitle" }, [
                _vm._v("服务商查询"),
              ]),
              _c(
                "ul",
                { staticClass: "applicationAppGroup" },
                _vm._l(_vm.application.searchList, function (app, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      on: {
                        click: function ($event) {
                          return _vm.applicationClick(app)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "applicationSvg",
                          style: { background: "var(--themeColor)" },
                        },
                        [
                          _c("i-icon", {
                            attrs: {
                              color: "#fff",
                              name: app.icon,
                              size: "30",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("p", [_vm._v(_vm._s(app.name))]),
                    ]
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
        _vm.application.serviceList.length
          ? _c("div", [
              _c("div", { staticClass: "applicationTitle" }, [
                _vm._v("扩展功能"),
              ]),
              _c(
                "ul",
                { staticClass: "applicationAppGroup" },
                _vm._l(_vm.application.serviceList, function (app, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      on: {
                        click: function ($event) {
                          return _vm.applicationClick(app)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "applicationSvg",
                          style: { background: "var(--themeColor)" },
                        },
                        [
                          _c("i-icon", {
                            attrs: {
                              color: "#fff",
                              name: app.icon,
                              size: "30",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("p", [_vm._v(_vm._s(app.name))]),
                    ]
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
        _vm.application.orderList.length
          ? _c("div", [
              _c("div", { staticClass: "applicationTitle" }, [
                _vm._v("订单查询"),
              ]),
              _c(
                "ul",
                { staticClass: "applicationAppGroup" },
                _vm._l(_vm.application.orderList, function (app, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      on: {
                        click: function ($event) {
                          return _vm.applicationClick(app)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "applicationSvg",
                          style: { background: "var(--themeColor)" },
                        },
                        [
                          _c("i-icon", {
                            attrs: {
                              color: "#fff",
                              name: app.icon,
                              size: "30",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("p", [_vm._v(_vm._s(app.name))]),
                    ]
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
      ]),
      _vm.isFirst
        ? _c("i-skeleton", { attrs: { skList: _vm.skList } })
        : _vm._e(),
      !_vm.application.orderList.length &&
      !_vm.application.searchList.length &&
      !_vm.application.serviceList.length &&
      !_vm.isFirst
        ? _c("emptyBox")
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }